<template>
    <div class="chatbot-container">
        <div class="messages-container" ref="messagesContainer">
            <div v-for="(message, index) in messages" :key="index" class="message-wrapper" :class="message.type">
                <div class="message user-message" v-if="message.type === 'user'">{{ message.text }}</div>
                <div class="message bot-message" v-else v-html="message.text"></div>
            </div>
            <!-- Spinner when processing API request -->
            <div v-if="isLoading" class="spinner-container">
                <div class="spinner"></div>
            </div>
        </div>
        <div class="input-container">
            <input v-model="animatedInput" type="text" placeholder="Type your message..." :disabled="isLoading"
                @keyup.enter="sendMessage" class="input-field" />
            <button class="send-btn" @click="sendMessage" :disabled="isLoading">Send</button>
        </div>

        <!-- Dialog for CreateEditBooking -->
        <v-dialog v-model="isDialogOpen" max-width="500px">
            <v-card elevation="0">
                <v-card-title>
                    <v-btn prepend-icon="mdi-calendar" variant="text">Create a Reservation</v-btn>
                    <v-spacer />
                </v-card-title>
                <v-card-text>
                    <!-- Select Category -->
                    <v-expand-transition>
                        <v-autocomplete v-model="selectedCategory" :items="categories" label="Select Category"
                            item-value="name" item-title="name" placeholder="Category"
                            @update:modelValue="onCategoryChange" return-object variant="solo">
                            <!-- Custom dropdown items -->
                            <template v-slot:item="{ item, props }">
                                <v-list-item v-bind="props" class="d-flex align-start">
                                    <div>
                                        <!-- Category name -->
                                        <div style="font-weight: bold">{{ item.name }}</div>

                                        <!-- Small green text showing 'Locations' count -->
                                        <small style="display: block; color: #4caf50; font-size: 0.75rem">{{
                                            item?.raw?.pois?.ids?.length }} Pois</small>

                                        <!-- Floors line in red color -->
                                        <small style="color: cadetblue; display: block">
                                            Floors:
                                            {{
                                                item.raw.matchingFloors
                                                    ?.sort((a, b) => a.number - b.number)
                                                    .map(floor => floor.name)
                                                    .join(', ') || 'None'
                                            }}
                                        </small>
                                        <small
                                            style="border-top: 1px dotted lightgrey; display: block; margin-top: 5px"></small>
                                    </div>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-expand-transition>

                    <!-- Select Floor -->
                    <v-expand-transition>
                        <v-autocomplete v-if="showFloorDropdown" v-model="selectedFloor" :items="floors"
                            label="Select Floor" placeholder="Floor" @update:modelValue="onFloorChange" return-object
                            item-value="number" item-title="name" variant="solo">
                            <template v-slot:item="{ item, props }">
                                <v-list-item v-bind="props" class="d-flex align-start">
                                    <div>
                                        <!-- Category name -->
                                        <div style="font-weight: bold">{{ item.name }}</div>
                                        <small
                                            style="border-top: 1px dotted lightgrey; display: block; margin-top: 5px"></small>
                                    </div>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-expand-transition>

                    <!-- Select Room -->
                    <v-expand-transition>
                        <v-autocomplete v-if="showRoomDropdown" v-model="selectedRoom" :items="rooms"
                            label="Select Room" item-value="name" item-title="name" return-object placeholder="Room"
                            @update:modelValue="onRoomChange" variant="solo"></v-autocomplete>
                    </v-expand-transition>

                    <!-- Date Picker -->

                    <DatePicker v-model.range="selectedDateRange" :min-date="new Date()" is24hr mode="date"
                        v-if="showDatePicker" :max-date="new Date(new Date().setDate(new Date().getDate() + 58))"
                        expanded :disabled-dates="disabledDates" label="Select Date" />

                    <v-row class="align-center justify-space-between mt-1">
                        <!-- Start Time -->
                        <v-col cols="6" style="text-align: left">
                            <span style="display: block; margin-bottom: 5px; font-weight: bold"
                                v-if="showStartTime && showDatePicker && selectedDateRange.start && selectedDateRange.end">
                                Start Time
                            </span>
                            <DatePicker v-model="startTime" is24hr mode="time" :rules="startTimeRules"
                                v-if="showStartTime && showDatePicker && selectedDateRange.start && selectedDateRange.end"
                                label="Start Time" @update:modelValue="validateStartTime" />
                        </v-col>

                        <!-- End Time -->
                        <v-col cols="6" style="text-align: right">
                            <span style="display: block; margin-bottom: 5px; font-weight: bold"
                                v-if="showEndTime && showDatePicker && showStartTime && selectedDateRange.start && selectedDateRange.end">
                                End Time
                            </span>
                            <DatePicker v-model="endTime" is24hr mode="time" :rules="endTimeRules"
                                v-if="showEndTime && showDatePicker && showStartTime && selectedDateRange.start && selectedDateRange.end"
                                label="End Time" @update:modelValue="validateEndTime" />
                        </v-col>
                    </v-row>

                    <small v-if="showDatePicker" style="color: #4caf50; display: block; margin-top: 10px">
                        After selecting Date Time, you will be redirected to Detailed Booking Creation Screen.
                    </small>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="submitReservation" :disabled="!canSubmit">Submit</v-btn>
                    <v-btn text @click="isDialogOpen = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isLoadingDialog" max-width="300px" persistent>
            <v-card class="text-center">
                <v-card-text>
                    <v-progress-circular :model-value="progressValue" :rotate="360" :size="100" :width="15"
                        color="teal">
                        <template v-slot:default>{{ progressValue }}%</template>
                    </v-progress-circular>
                    <p class="mt-4">Checking availability... Please wait</p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { GoogleGenerativeAI } from '@google/generative-ai'
import DOMPurify from 'dompurify'
import { getPoiCategories, getFloors, getPois, getPoisByCategoryId } from '@/controllers/BaseController'
import { DatePicker } from 'v-calendar'
import lodash from 'lodash'
import moment from 'moment'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useOAuthService } from '@/services/OAuthService'

export default {
    name: 'ChatBotComponent',
    components: {
        DatePicker,
    },
    props: {
        profileName: {
            type: String,
            required: true,
        },
        language: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const messages = ref([
            {
                text: props.language === 'german'
                    ? `Hallo ${props.profileName}! Wie kann ich Ihnen helfen?`
                    : `Hello ${props.profileName}! How can I assist you?`,
                type: 'bot',
            }
        ]);

        const genAI = new GoogleGenerativeAI('AIzaSyDd4cSNFiWgg8vvVaeLkQm9fE29Ii91V5g')
        const models = ['gemini-1.5-flash', 'gemini-1.5-pro-002', 'gemini-1.4-flash-002', 'gemini-1.5-flash', 'gemini-1.5-flash-8b']
        const router = useRouter()
        const userInput = ref('') // Stores the input to send
        const animatedInput = ref('') // Gradual animation for text input
        const isLoading = ref(false)
        const micState = ref('idle') // 'idle', 'listening'
        const isMicDisabled = ref(false)
        const selectedCategory = ref(null)
        const selectedFloor = ref(null)
        const selectedRoom = ref(null)
        const OAuthService = useOAuthService()
        const userProfile = ref({})
        const selectedDateRange = ref({ start: null, end: null })
        const categories = ref([{ text: 'Meeting Room', value: 'meeting-room' }])
        const floors = ref([])
        const poiMinTime = ref('06:00')
        const poiMaxTime = ref('20:00')
        const startTime = ref(new Date().setHours(6, 0, 0, 0))
        const endTime = ref(new Date().setHours(20, 0, 0, 0))
        const showStartTime = ref(true)
        const showEndTime = ref(true)
        const rooms = ref([])
        const isLoadingDialog = ref(false)
        const progressValue = ref(0)
        const hasContextBeenSent = ref(false);
        const apiController = ref(null)



        const isFirstMessage = ref(true)
        const disabledDates = ref([
            {
                repeat: {
                    weekdays: [7, 1],
                },
            },
        ])
        let chatSession = null
        let currentModelIndex = 0



        const initializeChatSession = async (modelName) => {
            const model = genAI.getGenerativeModel({ model: modelName });

            // Prepare the context content
            const contextContent = `
        First read the following file and use it as a reference for all subsequent queries:
        --------------------
        ${projectContext.value}
        --------------------
        Instructions:
        - Analyze the user's question to determine intent.
        - If the user intends to "create a reservation" or "book a room," respond with the string "OPEN_BOOKING_DIALOG".
        - For other intents, respond with HTML-formatted text suitable for user-facing guidance. Use descriptive tags such as '<p>', '<ul>', '<li>', '<strong>', and '<em>' to enhance readability.
        - Avoid including technical details or code snippets unless specifically requested.
        - Ensure the response is in the "language: ${props.language}" specified. Write answers as if the user is interacting with a user-friendly interface.
    `;

            // Split contextContent into manageable parts (e.g., 1000 characters per part)
            const splitIntoParts = (content, maxLength = 1000) => {
                const parts = [];
                for (let i = 0; i < content.length; i += maxLength) {
                    parts.push({ text: content.substring(i, i + maxLength) });
                }
                return parts;
            };

            const contextParts = splitIntoParts(contextContent);

            const generationConfig = {
                temperature: 1,
                topP: 0.95,
                topK: 40,
                maxOutputTokens: 19000,
                responseMimeType: "text/plain",
            };

            try {
                chatSession = await model.startChat({
                    generationConfig,
                    history: [
                        {
                            role: "user", // System instruction
                            parts: contextParts, // Pass as an array of parts
                        },
                    ],
                });
                console.log(`Chat session initialized with model: ${modelName}`);
            } catch (error) {
                console.error("Error initializing chat session:", error.message);
                chatSession = null; // Ensure chatSession is null on error
            }
        };


        const timeRules = computed(() => [
            {
                hours: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                minutes: { interval: 15 },
                seconds: 0,
                milliseconds: 0,
            },
        ])
        const startTimeRules = computed(() => [
            {
                hours: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19], // 06:00 - 19:45 hours
                minutes: { interval: 15 },
                seconds: 0,
                milliseconds: 0,
            },
            {
                hours: 19,
                minutes: [0, 15, 30, 45], // Allow up to 19:45
                seconds: 0,
                milliseconds: 0,
            },
        ])

        const endTimeRules = computed(() => {
            const start = moment(startTime.value)

            if (!start.isValid()) return { hours: [], minutes: { interval: 15 } }

            const minHour = start.hour()
            const minMinute = start.minute() + 15
            const maxHour = 20

            // Generate hours range
            const hours = []
            for (let hour = minHour; hour <= maxHour; hour++) {
                hours.push(hour)
            }

            // Minutes are always in intervals of 15
            const minutes = { interval: 15 }

            // Adjust for minimum time when at the starting hour
            if (minHour === maxHour && minMinute > 45) {
                // If we're at the max hour and past 45 minutes, restrict further selections
                return { hours: [maxHour], minutes: [] }
            }

            return { hours, minutes }
        })

        const validateStartTime = async time => {
            const start = moment(time)
            const end = moment(endTime.value)

            if (start.isSameOrAfter(end)) {
                showStartTime.value = false
                console.log('Start time is after or equal to end time')
                startTime.value = new Date(time)
                endTime.value = new Date(start.clone().add(15, 'minutes').valueOf())
                await nextTick()
                console.log('Start time updated and UI re-rendered:', startTime.value)
                showStartTime.value = true
            }
        }

        const validateEndTime = async time => {
            const start = moment(startTime.value)
            const end = moment(time)

            if (end.isSameOrBefore(start)) {
                showEndTime.value = false
                console.log('End time is before or equal to start time')
                endTime.value = new Date(start.clone().add(15, 'minutes').valueOf())
                await nextTick()
                console.log('End time updated and UI re-rendered:', endTime.value)
                showEndTime.value = true
            }
        }
        const isTimeRangeEqual = computed(() => {
            if (selectedDateRange.value?.start && selectedDateRange.value?.end) {
                const start = moment(selectedDateRange.value.start)
                const originalEndTime = moment(selectedDateRange.value.end)
                const end = moment(selectedDateRange.value.start)
                end.set({
                    date: start.date(),
                    month: start.month(),
                    year: start.year(),
                    hour: originalEndTime.hour(),
                    minute: originalEndTime.minute(),
                    second: 0,
                    millisecond: 0,
                })
                console.log(moment(start).format('YYYY-MM-DD HH:mm:ss'), moment(end).format('YYYY-MM-DD HH:mm:ss'))
                if (start.isSameOrAfter(end)) {
                    return true
                }
            }
            return false
        })
        watch(
            () => selectedDateRange.value,
            newRange => {
                if (newRange.start && newRange.end) {
                    const start = moment(newRange.start)
                    const end = moment(newRange.end)

                    // Auto-correct end time if it is not after start time
                    if (!end.isAfter(start)) {
                        selectedDateRange.value.end = start.add(15, 'minutes').toDate()
                    }
                }
            },
            { deep: true }
        )

        const showFloorDropdown = ref(false)
        const showRoomDropdown = ref(false)
        const showDatePicker = ref(false)

        const messagesContainer = ref(null)
        const projectContext = ref('')
        let recognition = null // Web Speech API instance
        const isDialogOpen = ref(false) // Dialog for CreateEditBooking
        let speechTimeout = null // Timeout for detecting silence

        const canSubmit = computed(() => {
            return (
                selectedCategory.value &&
                selectedFloor.value &&
                selectedRoom.value &&
                selectedDateRange.value.start &&
                selectedDateRange.value.end &&
                startTime.value &&
                endTime.value
            )
        })

        watch(messages, () => {
            if (messagesContainer.value) {
                messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
            }
        })

        // Sync animatedInput with userInput
        watch(animatedInput, newVal => {
            userInput.value = newVal
        })


        const loadProjectContext = async () => {
            try {
                const response = await fetch('/pia_context.md')
                let profile = await OAuthService.getUserProfile()
                let roles = profile.realm_access.roles
                profile.roles = roles
                userProfile.value = profile
                console.log(profile)
                if (!response.ok) throw new Error('Failed to load pia_context.md')
                projectContext.value = await response.text()
                // openBookingDialog();
            } catch (error) {
                console.error('Error loading pia_context.md:', error)
            }
        }

        const sendMessage = async () => {
            if (!userInput.value.trim()) return;

            const userMessage = userInput.value.trim();

            messages.value.push({ type: 'user', text: userMessage });

            userInput.value = ''; // Clear input field
            isLoading.value = true;

            try {
                const response = await chatSession.sendMessage(userMessage);
                const sanitizedReply = DOMPurify.sanitize(response.response.text());

                if (sanitizedReply.trim() === 'OPEN_BOOKING_DIALOG') {
                    return openBookingDialog();
                }

                messages.value.push({ type: 'bot', text: sanitizedReply });
            } catch (error) {
                console.error('Error sending message:', error.message);
                messages.value.push({ type: 'bot', text: '<p>An error occurred. Please try again later.</p>' });
            } finally {
                isLoading.value = false;
            }
        };

        const handleMicClick = async () => {
            if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
                alert('Speech Recognition API not supported in your browser.')
                return
            }

            if (micState.value === 'idle') {
                try {
                    await navigator.mediaDevices.getUserMedia({ audio: true })
                    startListening()
                } catch (error) {
                    console.error('Microphone access denied:', error)
                    isMicDisabled.value = true
                }
            } else {
                stopListening()
            }
        }

        const openBookingDialog = async () => {
            isDialogOpen.value = true // Open the booking dialog
            isLoading.value = false

            // Fetch categories and floors
            let CATEGORIES = await getPoiCategories()
            let FLOORS = await getFloors()
            CATEGORIES = lodash.orderBy(CATEGORIES, ['name'], ['asc'])
            CATEGORIES.forEach(category => {
                const categoryPoiIds = new Set(category.pois.ids)
                // Filter floors that have overlapping poi ids
                const matchingFloors = FLOORS.filter(floor => {
                    const floorPoiIds = new Set(floor.pois.ids)
                    return lodash.intersection([...categoryPoiIds], [...floorPoiIds]).length > 0
                })
                category.matchingFloors = matchingFloors
            })

            // Assign the enriched categories array to the categories variable
            categories.value = CATEGORIES
        }

        const submitReservation = () => {
            if (!selectedCategory.value || !selectedFloor.value || !selectedRoom.value || !selectedDateRange.value.start) {
                return
            }

            let startDateTime = moment(selectedDateRange.value.start).set({
                hour: moment(startTime.value).hour(),
                minute: moment(startTime.value).minute(),
                second: 0,
                millisecond: 0,
            })
            let endDateTime = moment(selectedDateRange.value.end).set({
                hour: moment(endTime.value).hour(),
                minute: moment(endTime.value).minute(),
                second: 0,
                millisecond: 0,
            })

            // Prepare the required data
            const params = {
                category_id: selectedCategory.value?.id,
                poi_id: selectedRoom.value?.id || selectedRoom.value?.pois?.ids[0],
                floorId: selectedFloor.value?.id,
                startDateTime: startDateTime,
                endDateTime: endDateTime,
                building_id: selectedRoom.value?.buildingId,
            }

            // Show loading dialog and reset progress
            isLoadingDialog.value = true
            progressValue.value = 0

            // Increment progress over 3 seconds
            const interval = setInterval(() => {
                progressValue.value += 1
                if (progressValue.value >= 100) {
                    clearInterval(interval)
                    isLoadingDialog.value = false // Hide loading dialog
                    router.push({
                        name: 'createManageBooking', // Ensure this route exists in your router
                        params: params,
                    })
                }
            }, 30) // 30ms interval for smooth progress
        }
        const startListening = () => {
            micState.value = 'listening'
            recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)()
            recognition.lang = props.language === 'german' ? 'de-DE' : 'en-US'
            recognition.continuous = true

            recognition.onresult = event => {
                clearTimeout(speechTimeout)
                let transcript = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join(' ')
                if (transcript !== '') {
                    transcript = ' ' + transcript
                }
                animateTextWriting(transcript)
                resetSpeechTimeout()
            }

            recognition.onerror = event => {
                console.error('Speech recognition error:', event.error)
                stopListening()
            }

            recognition.onend = () => {
                if (micState.value === 'listening') {
                    stopListening()
                }
            }

            recognition.start()
            resetSpeechTimeout()
        }

        const resetSpeechTimeout = () => {
            clearTimeout(speechTimeout)
            speechTimeout = setTimeout(() => {
                stopListening()
            }, 4000) // Stop listening after 4 seconds of silence
        }

        const stopListening = () => {
            if (recognition) {
                recognition.stop()
                recognition = null
            }
            micState.value = 'idle'
        }

        const animateTextWriting = text => {
            let index = 0
            const interval = setInterval(() => {
                if (index < text.length) {
                    animatedInput.value += text[index]
                    index++
                } else {
                    clearInterval(interval)
                }
            }, 50) // Gradual typing effect (50ms per character)
        }

        const micIcon = computed(() => (micState.value === 'listening' ? 'mdi mdi-microphone' : 'mdi mdi-microphone-outline'))

        const onCategoryChange = async () => {
            resetSelections()
            showFloorDropdown.value = false
            showRoomDropdown.value = false
            showDatePicker.value = false
            let category = JSON.parse(JSON.stringify(selectedCategory.value))
            floors.value = category.matchingFloors
            showFloorDropdown.value = true
        }

        const onFloorChange = async () => {
            selectedRoom.value = null
            showRoomDropdown.value = false
            showDatePicker.value = false
            let floor = JSON.parse(JSON.stringify(selectedFloor.value))
            let pois = await getPoisByCategoryId(selectedCategory.value.id)
            rooms.value = pois.filter(poi => poi?.floors?.ids[0] === floor.id)
            if (rooms.value.length === 1) {
                selectedRoom.value = rooms.value[0]
            }
            showRoomDropdown.value = true
        }

        const onRoomChange = () => {
            showDatePicker.value = true
        }

        const resetSelections = () => {
            selectedFloor.value = null
            selectedRoom.value = null
            selectedDateRange.value = { start: null, end: null }
            showFloorDropdown.value = false
            showRoomDropdown.value = false
            showDatePicker.value = false
        }

        onMounted(async () => {
            await loadProjectContext()
            initializeChatSession('gemini-1.5-flash')
            apiController.value = await import('@/controllers/BaseController');



        })
        return {
            messages,
            userInput,
            animatedInput,
            sendMessage,
            micState,
            handleMicClick,
            micIcon,
            isLoading,
            isDialogOpen,
            isMicDisabled,
            selectedCategory,
            selectedFloor,
            selectedRoom,
            selectedDateRange,
            categories,
            floors,
            rooms,
            disabledDates,
            timeRules,
            submitReservation,
            messagesContainer,
            openBookingDialog,
            onCategoryChange,
            onFloorChange,
            onRoomChange,
            showFloorDropdown,
            showRoomDropdown,
            showDatePicker,
            poiMaxTime,
            poiMinTime,
            isTimeRangeEqual,
            startTime,
            endTime,
            startTimeRules,
            endTimeRules,
            validateEndTime,
            validateStartTime,
            showStartTime,
            showEndTime,
            router,
            canSubmit,
            isLoadingDialog,
            progressValue,
            userProfile,
            OAuthService,
            isFirstMessage,
            initializeChatSession,
            projectContext,
            loadProjectContext,
            apiController
        }
    },
}
</script>

<style scoped>
/* Chatbot Container */
.swal2-container {
    z-index: 20000 !important;
}

/* Existing styles */
.v-dialog {
    z-index: 2000;
    /* Vuetify default */
}

.chatbot-container {
    display: flex;
    flex-direction: column;
    height: 600px;
    border-radius: 20px;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

/* Messages Container */
.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background: #fdfdfd;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #4caf50 #f0f0f0;
}

.messages-container::-webkit-scrollbar {
    width: 8px;
}

.messages-container::-webkit-scrollbar-thumb {
    background: #4caf50;
    border-radius: 4px;
}

/* Message Wrapper */
.message-wrapper {
    display: flex;
    margin-bottom: 10px;
}

/* User messages (Align Right) */
.user-message {
    background-color: #4caf50;
    color: white;
    align-self: flex-end;
    max-width: 60%;
    text-align: left;
    padding: 12px 16px;
    border-radius: 12px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    word-wrap: break-word;
}

/* Bot messages (Align Left) */
.bot-message {
    background-color: #f1f1f1;
    color: #333;
    align-self: flex-start;
    max-width: 60%;
    text-align: left;
    padding: 12px 16px;
    border-radius: 12px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    word-wrap: break-word;
}

/* Align User messages to Right */
.message-wrapper.user {
    justify-content: flex-end;
}

/* Align Bot messages to Left */
.message-wrapper.bot {
    justify-content: flex-start;
}

/* Input Container */
.input-container {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background: #ffffff;
    border-top: 1px solid #ddd;
}

/* Input Field */
.input-field {
    flex-grow: 1;
    padding: 12px 16px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 25px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.input-field:focus {
    border-color: #4caf50;
    box-shadow: 0px 0px 8px rgba(76, 175, 80, 0.2);
}

/* Send Button */
.send-btn {
    background: linear-gradient(145deg, #4caf50, #45a047);
    color: white;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    width: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-btn:hover {
    transform: scale(1.1);
    background: #42a647;
}

.send-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
}

/* Spinner */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #4caf50;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .chatbot-container {
        height: 100%;
        width: 100%;
        border-radius: 0;
    }

    .messages-container {
        padding: 10px;
    }

    .message {
        font-size: 14px;
        margin: 8px 0;
        padding: 10px;
    }

    .input-container {
        gap: 8px;
        padding: 10px;
    }

    .input-field {
        font-size: 14px;
        padding: 10px 14px;
    }

    .send-btn {
        width: 60px;
        height: 40px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .chatbot-container {
        height: 100%;
        width: 100%;
        border-radius: 0;
        padding: 5px;
    }

    .messages-container {
        padding: 5px;
    }

    .message {
        font-size: 12px;
        margin: 6px 0;
        padding: 8px;
    }

    .input-container {
        gap: 5px;
        padding: 5px;
    }

    .input-field {
        font-size: 12px;
        padding: 8px 10px;
    }

    .send-btn {
        width: 50px;
        height: 35px;
        font-size: 12px;
    }
}
</style>